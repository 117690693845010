import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
import { architectureReviewService } from '../../../data/SiteConfig';
import { printGbpWithUsd } from '../../utils/formatting';
import ContactForm from '../../components/ContactForm';
import Testimonial from '../../components/Testimonial';
import SimpleFaqList from '../../components/SimpleFaqList';
export const faqs = [{
  question: 'What happens after I apply?',
  answer: 'I will review your application and reply by email, typically within one business day, with any immediate follow-up questions, and to schedule a preliminary conversation to ensure the service is the right fit for you. In this call, I’ll let you know when I’m available to start working with you.'
}, {
  question: 'When will we have our workshop meeting?',
  answer: 'After payment is received, I’ll provide you with a calendar link to book our workshop session. Note that I only host meetings during my working hours of Monday to Friday, 9am to 5pm Irish Time (UTC+0 in winter, UTC+1 in summer).'
}, {
  question: 'What are your payment terms?',
  answer: 'I will provide you with a fixed price after our initial call. Payment is due in full before commencing our engagement. I accept payments by credit card (all countries) or bank transfers in the US and UK.'
}, {
  question: 'I still have more questions that I’d like to ask before applying.',
  answer: 'No worries. Please go ahead and complete the form below and let me know about what further questions you have.'
}];
export const _frontmatter = {};
const layoutProps = {
  faqs,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Who’s this for?`}</h2>
    <p>{`You have an existing serverless application running on AWS. You have concerns about the functionality, scalability or maintainability of your architecture or development processes as your product grows. Perhaps you’re encountering performance issues, or you’re not sure how to best use specific AWS services or manage your AWS environments. Or perhaps you want to move an existing server-based application to a serverless architecture. Whatever the particular issues you wish to address, I can help you overcome them in this engagement.`}</p>
    <h2>{`What’s included?`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Questionnaire`}</strong>{`: A detailed assessment of your current architecture and development processes`}</li>
      <li parentName="ul"><strong parentName="li">{`Diagnostic Workshop`}</strong>{` (2–3hrs video call): Information gathering session with your developers to go deeper on the specific issues you wish to target`}</li>
      <li parentName="ul">{`Written `}<strong parentName="li">{`Application Review Report`}</strong>{` tailored to the specific issues you wish to address. The report includes:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Risks`}</strong>{` and their causes I identified in your current architecture and development processes`}</li>
          <li parentName="ul"><strong parentName="li">{`Detailed recommendations`}</strong>{` of solutions to address these risks`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Q & A Session`}</strong>{` (1hr video call): Post-report follow-up call discussing the report and answering your questions`}</li>
    </ul>
    <p>{`In addition to these, I can provide ongoing help during the implementation of the recommendations based on the level of support you require.`}</p>
    <p>
  Price: from{' '}
  <span className="font-bold">
    {printGbpWithUsd(architectureReviewService.priceGBP)}
  </span>
  .
    </p>
    <h2>What clients are saying</h2>
    <section className="mb-8">
      <Testimonial authorName="Darren Gibney" authorTitle="Partner &amp; CTO at 3Advance" authorPhotoUrl="/img/client-logos/darren-gibney.jpg" mdxType="Testimonial">
  <div>
    <p>
      Paul’s focus on developer experience was probably the thing I most liked
      about his work with us. We frequently needed to onboard new team members
      and handoff projects to internal teams and this was key to long term
      success of projects. <strong>He raised the bar for our team</strong>, in
      many ways we’ve surpassed our previous stack in terms of IaC, CI/CD and
      testing.
    </p>
    <p>
      Paul knows his stuff and instantly had respect of all team members and
      leadership. Very diplomatic, change is not easy for all.{' '}
      <strong>
        Our team literally went from nothing on AWS and Serverless to feeling
        like there is not much we can’t do.
      </strong>
    </p>
  </div>
      </Testimonial>
      <Testimonial authorName="Peter Joseph" authorTitle="CTO at LandNow" authorPhotoUrl="/img/client-logos/peter-joseph.png" mdxType="Testimonial">
  <div>
    <p>
      <strong>The main takeaway from our engagement was confidence.</strong>
    </p>
    <p>
      The value of having an expert who is enjoyable to work with and capable of
      providing real-world guidance and advice is immense. Paul’s ability to
      review our solution and process and instill confidence in our work (or
      help address any risks) is highly valuable.
    </p>
  </div>
      </Testimonial>
      <Testimonial authorName="David Razidlo" authorTitle="Co-Owner, Game Haven" mdxType="Testimonial">
  <div>
    <p>
      Paul helped us transition our existing AppSync app away from Amplify CLI and manually created cloud resources to using the Serverless Framework. After a bit of hard work and help, we are comfortable with both decisions and feel they will be critical to our success.
    </p>
    <p>
      Paul has a deep understanding of industry best practices that goes beyond knowing the right thing to do. He pragmatically adapts to your immediate needs. He quickly understood the project and our business goals and he let us know when complexity was unwarranted and work was premature.
    </p>
  </div>
      </Testimonial>
    </section>
    <section>
  <h2>Still have questions?</h2>
  <SimpleFaqList faqs={faqs} mdxType="SimpleFaqList" />
    </section>
    <section id="applyForm" className="mt-10 lg:mt-2 mb-10 mx-auto">
  <h2>Apply now</h2>
  <p>
    To book an Architecture Review engagement or to learn more about how it
    could help you, fill out the form I’ll get back to you ASAP with details on
    how to proceed.
  </p>
  <div className="mt-10 lg:mt-2 mb-10 mx-auto font-sans">
    <ContactForm formName="ArchitectureReview" messageLabel="Tell me a little about your product/organisation" buttonText="Submit Request" hideSubject mdxType="ContactForm" />
  </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      